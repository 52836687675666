import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IPlayerWithVideo } from '../../types/player';
import { IPlayerVideo } from '../../types/video';
import Thumbnail from '../Thumbnail/Thumbnail';
import { IDefaultPropTypes, PlayerMode } from '../types/defaultPropTypes';
import styles from './player.module.sass';
import { VideoItemsContainer } from '../VideoItems/VideoItemsContainer';
import {
  selectButtonsScale,
  selectVideoHeight,
  selectVideoWidth,
} from '../../store/containerDimensions/selectors';
import {
  ITimelineItemClickDescriptor,
  ITimelineItemDismissDescriptor,
} from '../../types/types';
import DimmedBackground from '../DimmedBackground';
import { Chapters } from '../Chapters/Chapters';
import { AlternativeDurationLine } from '../Controls/AlternativeDurationLine/AlternativeDurationLine';
import { IGeneralVideoControlInterface } from '../Video/videoControlInterface';
import { DetachedStoreContext } from '../../contexts/DetachedStoreContext';
import { Video } from '../Video/Video';
import {
  registerVideoApi,
  setIsBotDetected,
} from '../../store/videoState/actions';
import { getChangingVideo } from '../../store/sourceConfiguration/selectors';
import {
  getIsVideoFinished,
  getShouldVideoBeVisible,
} from '../../store/videoState/selectors';
import { WelcomeBackLazyWrapper } from '../WelcomeBack/WelcomeBackLazyWrapper';
import {
  isSubtitlesFeatureEnabled,
  Loader,
  VideoFrame,
} from '@voomly/ui/player-deps';
import { usePrevious } from 'react-use';
import { Subtitles } from '../Subtitles/Subtitles';

// Lazy components
const LazyError = React.lazy(() => import('../Error/Error'));

interface IProps {
  player: IPlayerWithVideo;
  file: IPlayerVideo;
  playerMode: PlayerMode;

  onTimelineItemClick?: (item: ITimelineItemClickDescriptor) => void;
  onTimelineItemDismiss?: (
    item: ITimelineItemDismissDescriptor,
    remember?: boolean
  ) => void;

  allControlsAreAlwaysVisible: boolean;

  onRegisterVideoAPI: () => void;
}

const BLUR_DURATION = 400;

const Wrapper = styled.div<{ blurry?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: filter ${BLUR_DURATION}ms ease-out;
  filter: ${({ blurry }) => `blur(${blurry ? 10 : 0}px)`};
`;

const OldVideoFrameImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: blur(10px);
`;

const StyledLoader = styled(Loader)`
  z-index: 50;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const VideoWrapper = styled.div<{ isHidden?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ isHidden }) => (isHidden ? '0' : '1')};
`;

const VideoDoublerWrapper = styled.div<{ isHidden?: boolean }>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ isHidden }) => (isHidden ? '0' : '1')};
`;

const VideoWithControls = ({
  player,
  file,
  playerMode,
  onTimelineItemClick,
  onTimelineItemDismiss,
  allControlsAreAlwaysVisible,
  onRegisterVideoAPI,
}: IProps) => {
  const dispatch = useDispatch();
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoToPreload = useSelector(getChangingVideo);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const [isOldVideoFrameLoaded, setIsOldVideoFrameLoaded] = useState<
    boolean | undefined
  >();
  const [isShowingLoader, setIsShowingLoader] = useState(false);

  // Frame image
  const [oldVideoFrameUrl, setOldVideoFrameUrl] = useState<
    string | undefined
  >();

  // Video doubler
  const videoDoublerRef = useRef<HTMLVideoElement>(null);
  const [shouldUseDoubler, setShouldUseDoubler] = useState<boolean>(false);
  const [doublerFileUrl, setDoublerFileUrl] = useState<string>();
  const [videoDoublerFrame, setVideoDoublerFrame] = useState<
    number | undefined
  >();
  const showVideoDoubler = shouldUseDoubler && videoDoublerFrame !== undefined;

  useEffect(() => {
    canvasRef.current = document.createElement('canvas');
    return () => {
      canvasRef.current?.remove();
    };
  }, []);

  const prevVideoToPreload = usePrevious(videoToPreload);
  useEffect(() => {
    if (prevVideoToPreload || !videoToPreload) {
      return;
    }

    const ctx = canvasRef.current?.getContext('2d');
    if (canvasRef.current && videoRef.current) {
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;

      ctx?.drawImage(videoRef.current, 0, 0);

      try {
        canvasRef.current.toBlob((blob) => {
          blob && setOldVideoFrameUrl(URL.createObjectURL(blob));
        }, 'image/jpeg');
      } catch (e) {
        // fallback to video doubler
        setShouldUseDoubler(true);
      }
    }
  }, [prevVideoToPreload, videoToPreload]);

  useEffect(() => {
    if (prevVideoToPreload || !videoToPreload) {
      return;
    }

    if (videoRef.current) {
      setVideoDoublerFrame(videoRef.current.currentTime);
    }

    setDoublerFileUrl(file.url);
  }, [prevVideoToPreload, videoToPreload, file.url]);

  useEffect(() => {
    if (!videoToPreload) {
      setIsOldVideoFrameLoaded(false);

      if (shouldUseDoubler) {
        setVideoDoublerFrame(undefined);
        return;
      }

      setOldVideoFrameUrl(undefined);
    }
  }, [videoToPreload, shouldUseDoubler]);

  const handleLoadOldVideoFrame = useCallback(() => {
    setTimeout(() => {
      setIsOldVideoFrameLoaded(true);
    }, BLUR_DURATION); // Blur animation time
  }, []);

  const handleVideoDoublerLoaded = useCallback(() => {
    setIsOldVideoFrameLoaded(true);
  }, []);

  const handleRegisterVideoApi = useCallback(
    (api: IGeneralVideoControlInterface) => {
      dispatch(registerVideoApi({ api }));
      onRegisterVideoAPI();
    },
    [dispatch, onRegisterVideoAPI]
  );

  const [errorType, setErrorType] = useState<
    | 'PLAYER_NOT_FOUND'
    | 'VIDEO_SOURCE_ERROR'
    | 'INCORRECT_FILE_PASSWORD'
    | undefined
  >(undefined);

  const width = useSelector(selectVideoWidth);
  const height = useSelector(selectVideoHeight);
  const buttonsScale = useSelector(selectButtonsScale);
  const isVideoFinished = useSelector(getIsVideoFinished);
  const shouldVideoBeVisible = useSelector(getShouldVideoBeVisible);

  const triggerVideoSourceError = (message: string) => {
    console.log('Video Source Error', message);
    setErrorType('VIDEO_SOURCE_ERROR');
  };

  const handleToggleLoader = useCallback((isBuffering: boolean) => {
    setIsShowingLoader(isBuffering);
  }, []);

  const handleBotDetection = useCallback(
    (isBotDetected: boolean) => {
      dispatch(setIsBotDetected(isBotDetected));
      if (isBotDetected) {
        setIsShowingLoader(false);
      }
    },
    [dispatch]
  );

  // FileUrl is undefined
  if (errorType || !file.url)
    return (
      <Suspense fallback={null}>
        <LazyError
          errorType={errorType}
          videoConvertProgress={file.videoConvertProgress}
        />
      </Suspense>
    );

  const componentProps: IDefaultPropTypes = {
    playerMode,
    file,
    player,
    buttonsScale,
    allControlsAreAlwaysVisible,
  };

  return (
    <div className={cx(styles.videoRoot)}>
      <div className={styles.videoAndElements}>
        {/* This prevents screen flickering when first frame of the video interferes with the thumbnail animation */}
        <div
          className={styles.videoWrapper}
          style={{
            opacity:
              playerMode !== PlayerMode.THUMBNAIL_EDITOR && shouldVideoBeVisible
                ? 1
                : 0,
            width,
            height,
          }}
        >
          {/* Video that we always show, just changing file url when needed */}
          <Wrapper blurry={!!videoToPreload}>
            <VideoWrapper isHidden={!!videoToPreload && isOldVideoFrameLoaded}>
              <Video
                videoRef={videoRef}
                streamingUrl={
                  videoToPreload?.file?.url && isOldVideoFrameLoaded
                    ? videoToPreload?.file?.url
                    : file.url
                }
                player={player}
                registerVideoApi={handleRegisterVideoApi}
                onVideoSourceError={triggerVideoSourceError}
                onVideoBufferingToggle={handleToggleLoader}
                onBotDetection={handleBotDetection}
              />
            </VideoWrapper>

            {/* In case of "tainted canvas issue" use video doubler instead */}
            <VideoDoublerWrapper isHidden={!showVideoDoubler}>
              {videoDoublerFrame !== undefined &&
                shouldUseDoubler &&
                doublerFileUrl && (
                  <VideoFrame
                    url={doublerFileUrl}
                    onCanPlay={handleVideoDoublerLoaded}
                    fromTime={videoDoublerFrame}
                    videoRef={videoDoublerRef}
                  />
                )}
            </VideoDoublerWrapper>
          </Wrapper>
          {/* Previous video frame image to show while another video is buffering */}
          {oldVideoFrameUrl && (
            <OldVideoFrameImage
              src={oldVideoFrameUrl}
              onLoad={handleLoadOldVideoFrame}
              alt={file.name}
            />
          )}
          <StyledLoader
            isVisible={isShowingLoader}
            color={player.skin.bgColorEnabled ? player.skin.bgColor : undefined}
          />
        </div>
        {(playerMode !== PlayerMode.MINI_PLAYER || isVideoFinished) && (
          <Thumbnail {...componentProps} />
        )}
        <DimmedBackground {...componentProps} />
        <Chapters {...componentProps} />
        {isSubtitlesFeatureEnabled() && <Subtitles {...componentProps} />}
        <DetachedStoreContext.Consumer>
          {(contextProps) => {
            if (!contextProps) {
              return null;
            }

            return (
              <VideoItemsContainer
                onTimelineItemClick={onTimelineItemClick}
                onTimelineItemDismiss={onTimelineItemDismiss}
                buttonsScale={buttonsScale}
                playerMode={playerMode}
                itemWrapperHtmlElsRef={contextProps.itemWrapperHtmlElsRef}
              />
            );
          }}
        </DetachedStoreContext.Consumer>
        <WelcomeBackLazyWrapper {...componentProps} />
        <AlternativeDurationLine {...componentProps} />
      </div>
    </div>
  );
};

export default VideoWithControls;
